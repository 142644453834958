.services-first-sec{
    padding: 3% 0;
}

.services-first-sec h1 {
    font-size: 50px;
    font-weight: inherit;
}

.services-first-sec h2 {
    color: #00000085;
    font-size: 22px;
}

.services-first-sec p {
    font-size: 18px;
    color: black;
}
section.service-second-sec a:hover {
    color: #d40f67;
}
section.service-second-sec a {
    text-decoration: none;
}
.service-second-sec{
    padding: 6%;
}
.service-second-sec h1{
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 45px;
}
.service-second-sec p {
    text-align: center;
    color: black;
    font-size: 20px;
    
}
span.serv {
    font-size: 25px !important;
    font-weight: inherit !important;
}
.services-third-sec{
    padding: 6%;
    padding-top: 0;
}

.accordion-flush .accordion-item {
    margin-bottom: 3%;
}
.accordion-button:not(.collapsed) {
    color: white !important;
    background: #d40f67 !important;
    font-weight: 600;
}
td {
    padding: 10px 0!important;
    font-family: math;
    font-size: 17px;
    font-weight: 400;
}
button.accordion-button.collapsed {
    background: #aac6c9;
}

button.accordion-button.collapsed:hover {
    background: #d40f67;
    color: white;
}