.about-intro-img{
    text-align: right;
    float: right;
}
.meet-team-sec{
    padding: 3% 0;
}
.meet-team-sec h1{
    text-align: center;
    color: #000;
}
img.card-img-top.team-img {
    border-radius: 150px;
    width: 200px;
    border: 5px solid #40606c;
    text-align: center;
    margin: 0 auto;
    margin-bottom: -20%;
    z-index: 1;
}
.team-body.card-body {
    display: grid;
    padding-top: 20%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    border-top: 15px solid #002a3a;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 30px 0px #0000001c;
}
.card {
    box-shadow: none;
    margin-bottom: 1rem;
}
p.team-author-link {
    color: #2674b0;
    text-align: right;
}
p.team-sec-position {
    color: #0000009c;
    font-size: 16px !important;
    font-weight: normal;}
    p.team-desc.card-text {
        font-size: 18px;
    }