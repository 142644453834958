.nav-link {
    font-size: 20px !important;
}

nav.navbar {
    background: white !important;
    box-shadow: 0px 0px 50px 0px #00000014;
}
a.nav-link-url:hover {
    color: #d41a68;
}
.nav-link-url {
    text-decoration: none;
    color: #486472;
    font-size: 17px;
}
.navbar-nav .nav-item.dropdown:hover .dropdown-menu {
    display: block !important;
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.5);
    padding: 0 3em !important;
    font-size: 17px !important;
}   