body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.admin-sidebar {
  background-image: url(../src/assets/pink-pattern.png);
  background-position: 50%;
  background-size: 200px;
}



@media (min-width: 300px) and (max-width: 850px) {
  .intro-title {
    font-size: 80px !important;
}
.intro-icon {
  width: 300px !important;
  
}
.second-box {
  width: auto !important;
  margin-top: 10% !important;
}
.third-sec-parent {
  margin-top: 13em !important;
}
.third-sec h1 {
  font-size: 25px !important;
}

p.c-p.card-text {
  padding: 0% !important;
}
.fourth-sec h1 {
  color: white !important;
}
.fourth-sec h4 {
  font-size: 17px !important;
}
*.footer {
  padding: 0 !important;
}
section.services-first-sec img {
  width: 300px !important;
}
.refunds-service-sec-1 h2 {

  font-size: 20px !important;
}
.refunds-service-sec-1 h1 {
  font-size: 25px !important;
}
.refunds-service-sec-1 p {
  font-size: 17px !important;
}
button.blue-btn {
  font-size: 20px !important;
}
.refunds-service-sec-2 h1 {
  font-size: 25px !important;
}
.refunds-service-sec-2 p{
  font-size: 17px !important;
}
.refunds-service-sec-3 h1{
  font-size: 25px !important;
}

.refunds-service-sec-3 p{
  font-size: 17px !important;
}
.about-intro-img {
  width: 300px !important; 
}
.fourth-sec h1 {
  font-size: 25px !important;
}
.refunds-p {
  font-size: 17px !important;
}

section.blog-inner-detail-sec {
  padding: 0% !important;
}


p.blog-inner-detail-desc {
  font-size: 17px !important;
}
.blog-inner-detail-left-col img {width: 340px !important;}
.blog-inner-detail-right-col {
  margin: 0% !important;
  padding: 0 !important;
}


}