.contact-intro-title{
    text-align: center;
    color: white;
    font-size: 40px;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}
.second-sec-contact{
    color: black;
    padding: 6%;
}
.second-sec-contact p {
    font-size: 20px;
    text-align: center;
}
.contactintrosec{
    background-image: url('../../assets/pink-pattern.png');
    background-position: 50%;
    background-size: 200px;
    height: 200px;
}
section.second-sec-contact a {text-decoration: none;}

section.second-sec-contact a:hover {
    color: #d41367;
}