.footer{
    background-color: #f3f5f6;
}
.card-body {
    font-size: 20px;
    background: #f3f5f6;
}

.card-title.h5 {
    font-size: 22px;
}
.card-title.h5 a.nounderline {
    text-decoration: none;
    color: black;
}
.footer-card p a.nounderline {
    text-decoration: none;
    color: #40606c;
}
.card-header {
    border: none;
}
.privacy-policytext{
    font-size: 16px;
    color: #486472;
    width: 70%;
}
.footer-card{
    box-shadow: none;
}

.footer-card p {
    font-size: 17px;
    color: #40606c;
}

.footer-card .card-body {
    padding: 0;
}

.footer-card .card-header {
    padding: 0;
    border: 0;
}
.footer .fa-2x:hover {
    color: #2674b0;
}

.footer .fa-2x {
    font-size: 2em;
    color: #486472;
}