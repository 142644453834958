.introsec{
    background-image: url('../../assets/pink-pattern.png');
    background-position: 50%;
    background-size: 200px;
    height: 400px;
}
.intro-title{
    color: white;
    text-align: center;
    font-size: 80px;
}
.intro-icon{
    margin: 0 auto;
    text-align: center;
    display: inherit;
    width: 500px;
}
.third-sec.row a {text-decoration: none;}

.third-sec.row a:hover {
    color: #cd0e63;
}
button.white-btn {
    color: white;
    border: 2px solid white;
    transition: background-color 0.3s ease-in-out;
    font-size: 20px;
    margin: 0 auto;
    display: table;
    border-radius: 5px;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  .blog-tag-col > div {
    display: inline;
}
  
  button.white-btn:hover {
    background-color: white;
  }
button.blue-btn {
    background: #2674b0;
    font-size: 20px;
    padding-left: 3%;
    padding-right: 3%;
    border: none;
    width: 250px;
}

button.blue-btn:hover {
    background: #2674b0;
    font-size: 20px;
    padding-left: 3%;
    padding-right: 3%;
    color: white;
}

.second-sec{
    padding: 3% 0;

}


h2.second-sec-title {
    color: black;
    font-size: 35px;
    padding-top: 6%;
}

.second-sec p {
    font-size: 17px;
    color: black !important;
}
.second-box {
    background: #40606c;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 5%;
    padding-right: 5%;
    float: right;
}

.second-box p {
    color: white !important;
    font-size: 21px !important;
    font-style: italic;
}
.uicon{
    width: 100px;
    border-radius: 50px;
}

.user-white-box {
    border: 3px solid #40606c;
    background: white;
    padding: 1%;
    position: absolute;
}

.user-white-box h3 {
    padding-left: 15%;
    color: black;
    font-size: 20px;
    padding-top: 5%;

}

p.user-position {
    color: #00000085 !important;
    font-size: 17px !important;
    padding-left: 15%;
}
.third-sec-parent{
    padding-top:7%;
    padding-bottom: 3%;

}
.third-sec h1{
    font-size: 40px;
    color: black;
    width: 60%;
    margin: 0 auto;
}


.first-card.card-header {
    background: #002a3a;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
}



.first-card.card-header span {
    color: white;
    font-size: 45px;
    font-weight: 700;
}

.c-t.card-title.h5 {
    text-align: center;
    float: inherit;
}

p.c-p.card-text {
    text-align: center;
    color: black;
    font-size: 17px;
}

.secondd-card.card-header {
    background: #aac6c9;
    text-align: center;
    font-size: 18px;
    color: #002a3a;
    font-weight: 600 !important;
}

.secondd-card.card-header span {
    font-size: 45px;
    font-weight: 700;
    color: #002a3a;
}


.thirdd-card.card-header {
    background: #d40f67;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.thirdd-card.card-header span {
    font-size: 45px;
    font-weight: 700;
}

.card {
    border: none !important;
}
.fourth-sec{
    padding: 5% 0;
    background-image: url('../../assets/navy-pattern-1.png');
    background-position: 50%;
    background-size: 200px;
}
.fourth-sec h1{
    color: white;
    font-size: 33px;
    text-align: center;
}
.fourth-sec h4{
    color: white;
    font-size: 22px;
    text-align: center;
}
.fourth-sec button.blue-btn{
    margin: 0 auto;
    display: table;
}

.blog-sec button.blue-btn{
    float: right;
}
.blog-sec{
    padding: 3% 0;
}
.blogu-icon{
    border-radius: 50px;
    width: 50px;
}
.blogtag-btn{


}

button.blogtag-btn{
    background: #40606c;
    font-size: 14px;
    padding-left: 3%;
    padding-right: 3%;
    border: none;
    margin: 2%;
}

button.blogtag-btn:hover {
    background: #40606c;
    font-size: 17px;
    padding-left: 3%;
    padding-right: 3%;
    color: white;
}

.blog-body.card-body {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    background: white;}

.blog-box.card {
    box-shadow: 0px 0px 65px 0px #45454524;
}

p.blog-date {
    color: #0000007a;
    font-size: 17px;
}

.blog-title.card-title.h5 {
    font-size: 20px;
    padding-bottom: 2%;
}

p.blog-exerpt.card-text {
    font-size: 17px;
}

h6.blog-author {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: normal;
    padding-left: 4%;
}
p.author-pos {
    color: #40606c;
    font-size: 14px;
    padding-left: 4%;
}

a.blog-link {
    text-decoration: none;
    color: black;
}

a.blog-link img.card-img-top {
    width: 100%;
    max-height: 56.25%;
    object-fit: cover;
}

@media (min-width: 768px) {
    a.blog-link img.card-img-top {
        max-height: 121px;
    }
}

@media (min-width: 992px) {
    a.blog-link img.card-img-top {
        max-height: 166px;
    }
}

@media (min-width: 1200px) {
    a.blog-link img.card-img-top {
        max-height: 200px;
    }
}

@media (min-width: 1400px) {
    a.blog-link img.card-img-top {
        max-height: 234px;
    }
}

@media (max-width: 767px) {
    a.blog-link img.card-img-top {
        max-height: 344px;
    }
}