.refunds-service-sec-1{
    padding: 3% 0;
}
.refunds-service-sec-1 h1{
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 40px;
}
.refunds-service-sec-1 h2{
    color: #40606c;
    text-align: center;
    font-weight: inherit;
    font-size: 25px;
    padding-bottom: 1%;
}
.refunds-service-sec-1 p{
    padding-top: 1%;
    text-align: center;
    color: black;
    font-size: 18px;
    width: 80%;
    font-weight: inherit;
    margin: 0 auto;

}
.refunds-service-sec-1 .refundbtn {
    margin: 0 auto;
    display: table;
    width: 400px;
}
.refunds-service-sec-1 .refundbtn a {
    text-decoration: none;
}
.refunds-service-sec-2 {
    padding: 3% 0;
    background-color: #f3f5f6;
}
.refunds-service-sec-2 h1 {
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 40px;
}
.refunds-service-sec-2 p {
    text-align: center;
    color: black;
    font-weight: inherit;
    font-size: 18px;
    padding-top: 2%;
}
.refunds-service-sec-3{
    padding: 3% 0;
}
.refunds-service-sec-3 .col-md-5{
display: flex;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: center;
}
.refunds-service-sec-3 p {
    
    color: black;
    font-size: 18px;
    padding-top: 2%;
}
.refunds-h1{
    font-size: 33px !important;
    color: white;
}
.refunds-p{
    text-align: center;
    color: white;
    font-size: 22px;
}