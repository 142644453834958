.event-detail-sec-1{
    padding: 6%;
}
.event-detail-sec-1 h2{
    font-size: 45px;
    font-weight: inherit;
}
.sub-head-event-detail{
    font-size: 18px;
    text-align: left;
    color: grey;
}