.styles-module--downloadContainer--b7b2b {
  background-color: #fff;
  background-color: var(--color-white);
  border-radius: 4px;
  border-radius: 5px;
  box-shadow: 1px 1px 7px rgb(0 42 58 / 20%);
  box-shadow: 0 0 50px 0 #00000033;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  overflow: hidden;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: box-shadow 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, background-color 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, background-color 0.3s,
    -webkit-transform 0.3s;
  transition: var(--transition) box-shadow, var(--transition) transform,
    var(--transition) background-color;
  width: 100%;
}

a.styles-module--link--2993b {
  color: #486472;
  cursor: pointer;
  text-decoration: none;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.styles-module--download--d821d {
  width: 65%;
  margin: 0 auto;
}

.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  > div:first-child {
  align-items: center;
  display: flex;
  justify-content: center;
}

.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  > div {
  flex-shrink: 0;
  width: 50%;
}
.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  > div:first-child
  h3 {
  font-size: 25px;
}
.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  .styles-module--buttonDownload--b6ed2 {
  align-items: center;
  background-color: #7f949c !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  color: white;
  font-size: 25px;
}

.styles-module--auto--693af {
  width: auto;
}

.styles-module--small--d5a40 {
  font-size: 14px;
  padding: 10px 20px;
}

.styles-module--container--668a0 {
  background-color: #2674b0;
  background-color: var(--color-blue);
  border-radius: 4px;
  border-radius: var(--border-radius);
  color: #fff;
  color: var(--color-white);
  font-family: Lato, sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans;
  font-family: var(--font-family-heading);
  font-size: 16px;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s;
  transition: var(--transition) background-color;
}
