.resources-sec-1{
    padding: 3% 0;
}
.resources-sec-1 h1{
    text-align: center;
    color: black;
    font-weight: inherit;
    font-size: 60px;
}
.resources-sec-1{
    text-align: center;
    color: #000;
    font-size: 20px;
}
.resources-sec-1 .card {
    box-shadow: 0px 0px 30px 0px #cbcbcb;
}
.resources-second-sec{
    padding: 3% 0;
    background-color: #f3f5f6;
}
.modal-body {
    padding: 0 !important;
}
.custom-modal-dialog {
    max-width: 1000px !important; /* Set your desired width */
    width: 90%; /* Optional: Adjust width based on your design */
  }
.resources-second-sec h1{
    text-align: center;
    color: #000;
    font-size: 50px;
}
.modelimg{
    width: 450px;
    height:500px;
    margin: 0 auto;
    text-align: center;
    display: table;
    padding:2% ;
}
.resources-second-sec .card-body{
    background: white;
    text-align: center;
}

.pink-card.card-header {
    background: #d40f67;
    color: white;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.light-card.card-header {
    text-align: center;
    background: #aac6c9;
    font-weight: 600;
    font-size: 25px;
}