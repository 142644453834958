.blog-intro-title{
    color: white;
    text-align: center;
    font-size: 80px;
}
.blog-page-second-sec{
    padding: 3% 0;
}
.blog-page-second-sec{
    color: #000;
    font-size: 40px;
}
.blog-page-second-sec p {
    font-size: 20px;
    padding-bottom: 1%;
}

.blogintrosec{
    background-image: url('../../assets/pink-pattern.png');
    background-position: 50%;
    background-size: 200px;
    height: 200px;
}

p.blogpage-date {
    font-size: 15px;
}

.blogpage-title {font-size: 22px !important;}

p.blogpage-exerpt {
    font-size: 17px;
}

h6.blogpage-author {
    padding-left: 36px;
    color: black;
    font-size: 20px;
}

p.blogpage-author-position {
    color: #486472;
    font-size: 15px;
    padding-left: 36px;
}