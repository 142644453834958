section.blog-inner-detail-sec {
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 6%;
  margin-top: -10%;
}

.blog-inner-detail-left-col {
  padding-right: 5% !important;
  padding-left: 5% !important;
  padding-top: 3%;
  padding-bottom: 3%;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 50px 0px #0000001c;
}
.blog-inner-detail-left-col img {
  border-radius: 5px;
  padding-bottom: 3%;
}
.blog-inner-detail-left-col h1 {
  color: black;
  font-weight: inherit;
  font-size: 70px;
  padding-bottom: 2%;
}
p.blog-inner-detail-desc {
  font-size: 25px;
}

.blog-inner-detail-right-col {
  width: 400px !important;
  background: white;
  margin-left: 15%;
  border-radius: 10px;
  box-shadow: 0px 0px 50px 0 #0000001c;
  padding: 0 !important;
  margin-bottom: 10%;
}
.blog-inner-detail-right-col h2 {
  background: #002a3a;
  padding: 0 !important;
  color: white;
  text-align: center;
  padding-bottom: 5% !important;
  padding-top: 5% !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-inner-detail-right-col .blog1stcol {
  padding: 10%;
}
img.blogu-icon-details {
  width: 100px;
  border-radius: 200px;
}

.blog-inner-detail-right-col .blog1stcol .card {
  box-shadow: none !important;
}
a.blog-detail-return {
  font-size: 30px;
  color: white !important;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: 1%;
}

.blog-inner-detail-left-col img.table-backend-img-author {
  width: auto;
  height: auto;
  max-width: 150px;
  max-height: 150px;
}
